'use strict'

import AbstractVidaComponent from '@/vida/components/abstractvidacomponent.js'
import { computeLocation, computeLocationParams } from '@/vida/components/positionutils.js';

import Konva from 'konva';

export default class TextVidaComponent extends AbstractVidaComponent {

    constructor(vida, id) {
        super(vida, id);
    }

    toJson() {
        if (!this.konvaElement || !this.konvaElement.text) return { type: 'text' };
        console.assert(Math.abs(this.konvaElement.text.scaleX() - this.konvaElement.text.scaleY()) < 0.001);
        return {
            id: this.id,
            type: 'text',
            color: this.konvaElement.text.fill(),
            justification: this.konvaElement.text.align(),
            size: this.konvaElement.text.fontSize()*this.konvaElement.scaleX(),
            font: this.konvaElement.text.fontFamily(),
            text: this.konvaElement.text.text(),
            position: {
                x: this.position.x,
                y: this.position.y
            },
            angle: this.konvaElement.rotation(),
            zIndex: this.konvaElement.zIndex(),
            background: {
                enabled: this.background.enabled,
                color: this.konvaElement.background.fill(),
                padding: this.konvaElement.text.padding()
            }
        };
    }
    
    toPdf() {
        if (!this.konvaElement || !this.konvaElement.text) return { status: 'unsupported_option', content: {} };

        let status = 'ok';
        if (this.konvaElement.rotation() != 0 || this.background.enabled)
            status = 'unsupported_option'

        const { x, y } = computeLocation(this.position, this.documentSize, this.angle);
        
        let absolutePosition = { x, y };
        const konvaAlignment = this.konvaElement.text.align();
        absolutePosition.y -= this.konvaElement.text.height()/2;
        absolutePosition.y -= 0.12*this.konvaElement.text.height();

        if (konvaAlignment === 'left') {
            absolutePosition.x -= this.konvaElement.text.width()/2;

        } else if (konvaAlignment === 'right') {
            absolutePosition.x -= this.konvaElement.text.width() / 2;
            absolutePosition.x -= this.documentSize.width - this.konvaElement.text.width();

        } else if (konvaAlignment === 'center') {
            absolutePosition.x -= this.documentSize.width / 2;
        }
        
        return {
            content: {
                text: this.konvaElement.text.text(),
                font: this.konvaElement.text.fontFamily(),
                relativePosition: absolutePosition,
                fontSize: this.konvaElement.text.fontSize(),
                color: this.konvaElement.text.fill(),
                lineHeight: 0.82,
                alignment: konvaAlignment
            },
            status
        }
    }

    select() {
        if (!this.konvaElement) return;
        if (!this.konvaTransformer) {
            this.konvaTransformer = new Konva.Transformer({ 
                resizeEnabled: true, 
                rotateEnabled: true,
                enabledAnchors: [
                    'top-left',
                    'top-right',
                    'bottom-left',
                    'bottom-right',
                ],
                boundBoxFunc: function (oldBox, newBox) {
                    newBox.width = Math.max(1, newBox.width);
                    return newBox;
                },
                keepRatio: true
            });
        }
        this.konvaTransformer.nodes([this.konvaElement]);
        this.vida.registerTransformer(this.konvaTransformer);
        this.konvaElement.setDraggable(true);
        this.vida.draw();
    }
    
    showTextEditor() {
        this.konvaElement.text.hide();
        if (this.konvaTransformer) this.konvaTransformer.hide();

        // at first lets find position of text node relative to the stage:
        const textPosition = this.konvaElement.text.absolutePosition();

        const canvaScale = this.vida.stage.scale().x;
        
        // so position of textarea will be the sum of positions above:
        const areaPosition = {
            x: this.vida.stage.container().offsetLeft + textPosition.x - this.konvaElement.text.offsetX()*canvaScale,
            y: this.vida.stage.container().offsetTop + textPosition.y + this.konvaElement.text.offsetY()*canvaScale,
        };

        // create textarea and style it
        const textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        
        const textAreaFontSize = this.konvaElement.text.fontSize()*canvaScale;

        // apply many styles to match text on canvas as close as possible
        // remember that text rendering on canvas and on the textarea can be different
        // and sometimes it is hard to make it 100% the same. But we will try...
        textarea.value = this.konvaElement.text.text();
        textarea.style.position = 'absolute';
        textarea.style.top = areaPosition.y + 'px';
        textarea.style.left = areaPosition.x + 'px';
        textarea.style.width = (this.konvaElement.text.width() - this.konvaElement.text.padding() * 2 + 10)*canvaScale + 'px';
        textarea.style.height = (((this.konvaElement.text.height() - this.konvaElement.text.padding() * 2))*canvaScale  + 5) + 'px';
        textarea.style.fontSize = textAreaFontSize + 'px';
        textarea.style.border = '1px solid red';
        textarea.style.padding = '0px';
        textarea.style.margin = '0px';
        textarea.style.overflow = 'hidden';
        textarea.style.background = 'none';
        textarea.style.resize = 'none';
        textarea.style.outline = 'none';
        textarea.style.lineHeight = this.konvaElement.text.lineHeight();
        textarea.style.fontFamily = this.konvaElement.text.fontFamily();
        textarea.style.transformOrigin = 'center';
        textarea.style.textAlign = this.konvaElement.text.align();
        textarea.style.color = this.konvaElement.text.fill();
        const rotation = this.konvaElement.text.rotation();
        let transform = '';
        if (rotation) transform += 'rotateZ(' + rotation + 'deg)';

        let px = 0;
        // also we need to slightly move textarea on firefox
        // because it jumps a bit
        var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) px += 2 + Math.round(this.konvaElement.text.fontSize()*canvaScale / 20);
        transform += 'translateY(-' + px + 'px)';

        textarea.style.transform = transform;

        // reset height
        textarea.style.height = 'auto';
        // after browsers resized it we can set actual value
        textarea.style.height = textarea.scrollHeight + 3 + 'px';

        textarea.focus();

        const removeTextarea = () => {
            textarea.parentNode.removeChild(textarea);
            window.removeEventListener('click', handleOutsideClick);
            this.konvaElement.text.show();
            if (this.konvaTransformer) {
                this.konvaTransformer.show();
                this.konvaTransformer.forceUpdate();
            }
          }
  
        const setTextareaWidth = (newWidth) => {
            if (!newWidth) {
              // set width for placeholder
              newWidth = this.konvaElement.text.placeholder.length * this.konvaElement.text.fontSize();
            }
            // some extra fixes on different browsers
            var isSafari = /^((?!chrome|android).)*safari/i.test(
              navigator.userAgent
            );
            var isFirefox =
              navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (isSafari || isFirefox) {
              newWidth = Math.ceil(newWidth);
            }
  
            var isEdge =
              document.documentMode || /Edge/.test(navigator.userAgent);
            if (isEdge) {
              newWidth += 1;
            }
            textarea.style.width = newWidth + 'px';
          }
  
          textarea.addEventListener('keydown', (e) => {
            // hide on enter
            // but don't hide on shift + enter
            if (e.key === 'Enter' && !e.shiftKey) {
              this.update({ text: textarea.value});
              this.vida.onComponentUpdated(this.id);
              removeTextarea();

            }
            // on esc do not set value back to node
            if (e.key === 'Esc') {
              removeTextarea();
            }
          });
  
          textarea.addEventListener('keydown', () => {
            const scale = this.konvaElement.text.getAbsoluteScale().x;
            setTextareaWidth(this.konvaElement.text.width() * scale);
            textarea.style.height = 'auto';
            textarea.style.height =
              textarea.scrollHeight + this.konvaElement.text.fontSize() + 'px';
          });
  
          const handleOutsideClick = (e) => {
            if (e.target !== textarea) {
                this.update({ text: textarea.value});
                this.vida.onComponentUpdated(this.id);
                removeTextarea();
            }
          }
          setTimeout(() => {
            window.addEventListener('click', handleOutsideClick);
          });
    }

    unselect() {
        if (this.konvaElement.text) this.konvaElement.text.setDraggable(false);
        if (this.konvaTransformer) this.konvaTransformer.detach();
    }

    destroy() {
        if (!this.konvaElement) return;
        if (this.konvaElement.text) this.konvaElement.text.destroy();
        if (this.konvaElement.background) this.konvaElement.background.destroy();
        this.konvaElement.destroy();
    }


    internalUpdate(options, document=null) {
        return new Promise((resolve) => {
            if (this.inPromise) { resolve(); return; }
            this.inPromise = true;
           
            if (options.id) this.id = options.id;
            if (options.zIndex !== undefined && (this.zIndex === undefined || options.zIndex !== this.zIndex)) {
                this.zIndex = options.zIndex;
            }

            if (!this.konvaElement) {
                this.konvaElement = new Konva.Group();

                this.konvaElement.text = new Konva.Text({
                    draggable: false,
                    text: options.text,
                    fill: options.color,
                    align: options.justification,
                    fontSize: options.size,
                    fontFamily: options.font,
                    padding: 0,
                    verticalAlign: 'bottom'
                });
                this.konvaElement.on('click', () => {
                    this.vida.selectComponent(this.id);
                });

                this.konvaElement.text.on('dblclick dbltap', () => {
                    this.showTextEditor();
                });

                this.konvaElement.on('dragend', () => {
                    this.adjustPosition()
                });
                this.konvaElement.on('transform', () => {
                    this.adjustSizeAndAngle()
                });
                
                this.konvaElement.background = new Konva.Rect();
                console.assert(options.background !== undefined); // since 0.9.1
                this.konvaElement.background.setFill(options.background.color);
                
                this.background = {};
                this.background.enabled = options.background.enabled;
                
                this.konvaElement.add(this.konvaElement.background);
                this.konvaElement.add(this.konvaElement.text);

                this.vida.registerComponent(this.konvaElement);
            }
            if (options.text !== undefined && this.konvaElement.text.text() !== options.text) {
                this.konvaElement.text.setText(options.text);
                this.recomputePosition = true;
            }
            if (options.font !== undefined && this.konvaElement.text.fontFamily() !== options.font) {
                this.konvaElement.text.setFontFamily(options.font);
                this.recomputePosition = true;
            }
            
            if (options.size !== undefined && !this.konvaElement.text.fontSize() !== options.size) {
                this.konvaElement.text.setFontSize(options.size);
                this.konvaElement.scaleX(1);
                this.konvaElement.scaleY(1);
                this.recomputePosition = true;
            }
            
            if (options.color !== undefined && !this.konvaElement.text.fill() !== options.color) {
                this.konvaElement.text.setFill(options.color);
                this.draw = true;
            }

            if (options.justification !== undefined && !this.konvaElement.text.align() !== options.justification) {
                this.konvaElement.text.setAlign(options.justification);
                this.draw = true;
            }
            
            if (options.background !== undefined) {

                if(options.background.color !== undefined && this.konvaElement.background.fill() !== options.background.color) {
                    this.konvaElement.background.setFill(options.background.color);
                    this.draw = true;
                }

                if (options.background.enabled !== undefined && this.background.enabled !== options.background.enabled) {
                    this.background.enabled = options.background.enabled;
                    if (this.background.enabled) {
                        this.konvaElement.background.show();
                    } else {
                        this.konvaElement.background.hide();
                    }
                    this.draw = true;
                }
            }

            if (options.angle !== undefined && options.angle !== this.angle) {
                this.angle = options.angle;
                this.konvaElement.rotation(this.angle);
                this.recomputePosition = true;
            }


            const documentDimensionChanged = !this.documentSize || document && (this.documentSize.width !== document.width || this.documentSize.height !== document.height);
            const positionChanged = this.position === undefined || (options.position !== undefined && (
                (options.position.x !== undefined && this.position.x !== options.position.x) ||
                (options.position.y !== undefined && this.position.y !== options.position.y)));

            if (this.recomputePosition 
                ||  positionChanged
                || documentDimensionChanged) {
                this.recomputePosition = false;
                if (!this.documentSize) this.documentSize = {width: 0, height: 0};
                if (document && document.width) this.documentSize.width = document.width;
                if (document && document.height) this.documentSize.height = document.height;
                
                if (options.position) {
                    if (this.position == undefined) this.position = {};
                    if (options.position.x) this.position.x = options.position.x;
                    if (options.position.y) this.position.y = options.position.y;
                }
                const { x, y } = computeLocation(this.position, this.documentSize, this.angle);
            
                const padding = 0;    
                this.konvaElement.setAttrs({ 
                    x: x - padding, 
                    y: y - padding, 
                    offsetX: this.konvaElement.text.width()/2 + padding,
                    offsetY: this.konvaElement.text.height()/2 + padding,
                    width: this.konvaElement.text.width() + 2*padding,
                    height: this.konvaElement.text.height() + 2*padding
                });

                this.konvaElement.text.setAttrs({
                    x: padding,
                    y: padding
                })

                if (this.background.enabled) {
                    this.konvaElement.background.setAttrs({
                        width: this.konvaElement.text.width() + 2*padding,
                        height: this.konvaElement.text.height() + 2*padding,
                    });
                }
                if (this.konvaTransformer !== undefined) {
                    this.konvaTransformer.forceUpdate();
                }
                this.recomputePosition = true;

                this.draw = true;
            }
            
            if (options.zIndex && (options.zIndex !== this.konvaElement.zIndex())) {
                this.zIndex = options.zIndex;
                this.konvaElement.zIndex(this.zIndex);
                this.draw = true;
            }

            if (this.draw) {
                this.draw = false;
                this.vida.draw();
            }
            this.inPromise = false;
            resolve();
        });
    }
    
    adjustPosition() {
        if (!this.konvaElement.text) return;
        this.position = computeLocationParams({ x: this.konvaElement.x(), y: this.konvaElement.y() }, this.documentSize);
        this.konvaElement.background.setWidth(this.konvaElement.text.width());
        this.vida.onComponentUpdated(this.id);
    }
    
    adjustSizeAndAngle() {
        if (!this.konvaElement) return;
        this.position = computeLocationParams({ x: this.konvaElement.x(), y: this.konvaElement.y() }, this.documentSize);
        this.vida.onComponentUpdated(this.id);
    }
}