'use strict';

import symbols from '@/assets/local/data/symbols.json';
import backgrounds from '@/assets/local/data/backgrounds.json';
import templates from '@/assets/local/data/templates.json';
import colors from '@/assets/local/data/colors.json';
import formats from '@/assets/local/data/formats.json';

import Vue from 'vue'

const today = new Date();

function isNew(element) {
    if (!element['creation_date']) return false;
    const date = new Date(element['creation_date'] + 'Z');
    const nbDays = (today - date) / (1000 * 60 * 60 * 24); 
    return nbDays < 7;
}


function mapElements(elements) {
    elements = elements.map((element) => ({
        ...element,
        isNew: isNew(element)
    }));
    return elements.sort((a, b) => {
        if (a['creation_date'] && !b['creation_date']) 
            return -1
        if (!a['creation_date'] && b['creation_date']) 
            return +1
        if (a['creation_date'] === b['creation_date'])
            return a['id'] < b['id'];
        return a['creation_date'] < b['creation_date'];
    });
}

function computeNbNewElements(elements) {
    let nbNew = 0;
    elements.forEach((element) => nbNew += element['isNew']);
    return nbNew;
}

Vue.prototype.$symbols = mapElements(symbols);
Vue.prototype.$backgrounds = mapElements(backgrounds);
Vue.prototype.$templates = mapElements(templates);
Vue.prototype.$colors = mapElements(colors);
Vue.prototype.$formats = mapElements(formats);

Vue.prototype.$nbNew = {
    'symbols': computeNbNewElements(Vue.prototype.$symbols),
    'backgrounds': computeNbNewElements(Vue.prototype.$backgrounds),
    'templates': computeNbNewElements(Vue.prototype.$templates),
    'colors': computeNbNewElements(Vue.prototype.$colors),
    'formats': computeNbNewElements(Vue.prototype.$formats),
}
